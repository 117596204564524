<template>
  <div
    id="photoboothController"
    ref="photobooth"
    class="section"
    :class="controllerBackground"
  >
    <template v-if="serverState < 3">
      <!-- GAME STARTED -->
      <template v-if="gameSubState == SUBSTATES.tutorial">
        <div id="tutorial" class="p-5">
          <div class="descripcion">
            <p class="mb-5 has-text-weight-bold">
              {{ $t("exp_phototbooth.tutorialTitle") }}
            </p>
            <div class="image-container">
              <img
                :src="tutorialSrc"
                class="mb-5"
                width="103"
                height="181"
                alt="Tutorial photobooth"
              />
            </div>
            <ol>
              <li v-if="showStickers">
                {{ $t("exp_phototbooth.tutorialStickers") }}
              </li>
              <li v-if="isVideoMode">
                {{ $t("exp_phototbooth.tutorialVideo") }}
              </li>
              <li v-else>{{ $t("exp_phototbooth.tutorialPhoto") }}</li>
              <li>{{ $t("exp_phototbooth.tutorialShare") }}</li>
            </ol>
          </div>

          <b-button
            @click="gameSubState = SUBSTATES.creating"
            expanded
            type="is-primary"
            >{{ $t("exp_phototbooth.btnStart") }}</b-button
          >
        </div>
      </template>

      <template v-else>

        <div
          id="creatingAndCapturing"
          v-if="
            gameSubState >= SUBSTATES.creating &&
            gameSubState <= SUBSTATES.convertingUploading
          "
        >
          <div v-show="showStickers" class="p-5" :class="showDragContainer ? 'flex-grow' : 'is-absolute'">
            <div
              v-if="showDragContainer"
              class="dragContainer"
              :style="{ height: dragContainerHeight + 'px' }"
              ref="dragContainer"
            >
              <p
                v-if="stickerList.filter((x) => x.enabled).length === 0"
                class="p-5 has-text-grey"
              >
                {{ $t("exp_phototbooth.stickersPlaceholder") }}
              </p>

              <template v-if="dragContainerHeight > 0">
                <template
                  v-for="sticker in stickerList.filter((x) => x.enabled)"
                >
                  <vue-draggable-resizable
                    :w="75"
                    :h="75"
                    :x="sticker.x * dragContainerWidth"
                    :y="sticker.y * dragContainerHeight"
                    :parent="true"
                    :key="sticker.id"
                    :resizable="false"
                    @dragging="sticker.onDrag"
                  >
                    <!-- Icono o el video -->
                    <template v-if="sticker.icon">
                      <img :src="sticker.icon.sizes.thumbnail.url ?? sticker.icon.url" />
                    </template>
                    <template v-else-if="sticker.image">
                      <img :src="sticker.image.sizes.thumbnail.url ?? sticker.image.url" />
                    </template>
                    <template v-else>
                      <video
                        :src="sticker.video.url"
                        disableRemotePlayback
                        muted
                        autoplay
                        loop
                      ></video>
                    </template>
                  </vue-draggable-resizable>
                </template>
              </template>
            </div>
            <div
              id="recordingState"
              :style="{ width: showDragContainer ? `${dragContainerWidth}px` : 'calc(100% - 3rem)' }"
              :class="[gameSubStateName]"
            >
              <div v-if="gameSubState == SUBSTATES.startingCapture">
                <d-icon icon="FaHourglass" size="is-small" class="mr-1"/>
                <span>{{ $t("exp_phototbooth.stateBeReady") }}</span>
              </div>
              <div v-if="gameSubState == SUBSTATES.capturing">
                <d-icon icon="FaVideo" size="is-small" class="mr-1"/>
                <span>{{ $t("exp_phototbooth.stateCapturing") }}</span>
              </div>
              <div v-if="gameSubState == SUBSTATES.convertingUploading">
                <d-icon :icon="isVideoMode ? 'FaHourglass' : 'FaCamera'" size="is-small" class="mr-1"/>
                <span>{{ $t("exp_phototbooth.stateProcessing") }}</span>
              </div>
            </div>

            <div id="stickersContainer" v-if="showStickers && stickerList.length > 1">
              <div
                v-for="sticker in stickerList"
                :key="sticker.id"
                class="sticker"
                :class="{ enabled: sticker.enabled }"
                @click="ToggleSticker(sticker)"
              >
                <template v-if="sticker.icon">
                  <img :src="sticker.icon.sizes.thumbnail.url ?? sticker.icon.url" />
                </template>
                <template v-else-if="sticker.image">
                  <img :src="sticker.image.sizes.thumbnail.url ?? sticker.image.url" />
                </template>
                <template v-else>
                  <video
                    :src="sticker.video.url"
                    disableRemotePlayback
                    muted
                  ></video>
                </template>
              </div>
            </div>
          </div>

          <template
            v-if="
              gameSubState >= SUBSTATES.creating &&
              gameSubState <= SUBSTATES.captureFinished
            "
          >
            <div
              class="has-background-white-ter"
              :class="showDragContainer ? 'small-button' : 'big-button'"
            >
              <b-button
                v-if="isVideoMode"
                type="is-primary"
                @click="TakeVideo()"
                :disabled="gameSubState != SUBSTATES.creating"
              >
                <d-icon icon="FaVideo" size="is-large" />
              </b-button>

              <b-button
                v-else
                size="is-large"
                type="is-primary"
                @click="TakePhoto()"
                :disabled="gameSubState != SUBSTATES.creating"
              >
                <d-icon icon="FaCamera" size="is-large" />
              </b-button>

              <div class="cta" :class="showDragContainer ? 'is-size-7' : 'is-size-5'">
                {{
                  isVideoMode
                    ? $t("exp_phototbooth.ctaStartToRecord")
                    : $t("exp_phototbooth.ctaTakePicture")
                }}
              </div>
            </div>
          </template>
        </div>

        <template v-if="gameSubState == SUBSTATES.captureFinished">
          <div id="capturedVideo" v-if="isVideoMode" class="p-5">
            <div class="main-container">
              <div class="has-text-centered">
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M54 6H6V42H54V6ZM0 0V60H60V0H0Z"
                    fill="#0A0A0A"
                  />
                  <path
                    d="M37.6418 23.8404L26.3272 17.0124C25.4079 16.4579 24 16.996 24 18.3675V32.0202C24 33.2506 25.3083 33.9922 26.3272 33.3753L37.6418 26.5506C38.6511 25.9436 38.6543 24.4474 37.6418 23.8404Z"
                    fill="#0A0A0A"
                  />
                </svg>
                <h2 class="title has-text-black-bis mb-2">
                  {{ $t("exp_phototbooth.videoReadyTitle") }}
                </h2>
                <div class="is-size-4">
                  {{ $t("exp_phototbooth.videoReadyDescription") }}
                </div>
              </div>
            </div>

            <div class="bottom-container">
              <div>
                <b-button
                  type="is-primary"
                  expanded
                  outlined
                  @click="ReshootVideo()"
                  >{{ $t("exp_phototbooth.btnRedo") }}</b-button
                >
                <b-button type="is-primary" expanded @click="SaveVideos()">{{
                  $t("exp_phototbooth.btnContinue")
                }}</b-button>
              </div>
            </div>
            <b-progress
              class="mt-2"
              type="is-primary"
              size="is-small"
              :value="sharingTimePercentLeft"
            ></b-progress>
          </div>

          <!-- Captured Image -->
          <div v-else id="capturedImage" class="is-flex" >
            <div id="imagesContainer">
                <div>
                  <img v-for="image in capturedImages"  :key="image.id" :src="image.mobile" @load="addRevealClass($event)"/>
                </div>
            </div>

            <div class="bottom-container m-3">
              <b-button
                type="is-primary"
                expanded
                outlined
                @click="ReshootPhotos()"
                >{{ $t("exp_phototbooth.btnReshoot") }}</b-button
              >
              <b-button type="is-primary" expanded @click="SavePhotos()">{{
                $t("exp_phototbooth.btnSave")
              }}</b-button>
            </div>
          </div>
        </template>
      </template>
    </template>

    <template v-if="serverState == 3">
      <div class="time-out has-text-black-bis has-text-centered">
        <div>
          <div>
            <d-icon icon="FaHourglassEnd" size="is-large" />
            <p class="is-size-2 has-text-weight-bold">
              {{ $t("exp_phototbooth.timeOut") }}
            </p>
            <p class="is-size-5">
              {{ $t("exp_phototbooth.thanksForParticipating") }}
            </p>
          </div>
        </div>
        <b-button type="is-primary" @click="$parent.GoToMenu()" expanded>{{
          $t("exp_phototbooth.backToMenu")
        }}</b-button>
      </div>
    </template>
    <canvas id="confetti-canvas"></canvas>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import ConfettiGenerator from "confetti-js";
const utils = require("@/components/utils.js");

export default {
  components: {
    VueDraggableResizable,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      SUBSTATES: Object.freeze({
        tutorial: 0,
        creating: 1,
        startingCapture: 2,
        capturing: 3,
        convertingUploading: 4,
        captureFinished: 5,
      }),
      gameSubState: 1,

      activeSlide: 0,
      sliderId: 0,
      sharingTimePercentLeft: 100,
      sharingTimeStarted: null,
      sharingTimeInterval: null,

      fetchingVideoShare: false,

      showTutorial: true,
      stickerList: [],
      screenTakingPicture: false,
      showCapturedImages: false,
      capturedImages: [],
      capturedVideo: null,
      showCapturedVideo: false,
      showCapturedVideoTimer: null,

      dragContainerHeight: 0,
      dragContainerWidth: 0,
    };
  },
  computed: {
    gameSubStateName() {
      return Object.keys(this.SUBSTATES)[this.gameSubState];
    },
    serverState() {
      return this.$parent.serverState;
    },
    showStickers() {
      return this.options.stickers.enabled && this.options.stickers.list.length > 0;
    },
    showDragContainer() {
      return this.showStickers && this.options.stickers.draggable
    },
    isVideoMode() {
      return this.options.captureMode == "video";
    },
    tutorialSrc() {
      if (this.options.tutorial?.image) return this.options.tutorial.image.url;
      if (this.showStickers) return "/assets/photobooth/tutorial-stickers.png";
      if (this.isVideoMode) return "/assets/photobooth/tutorial-video.png";
      return "/assets/photobooth/tutorial-photo.png";
    },
    moreOnePlayer() {
      return this.$parent.room.experience.component[0].queue.maxPlayers > 1;
    },
    controllerBackground() {
      return (
        (this.gameSubState == this.SUBSTATES.captureFinished ||
          this.serverState == 3) &&
        "has-background-grey-light"
      );
    },
  },
  methods: {
    SetSharingTimePercent() {
      this.sharingTimePercentLeft =
        100 -
        ((Date.now() - this.sharingTimeStarted) /
          1000 /
          (this.options.clipDuration * 2 - 1)) *
          100;
      // console.log("SetSharingTimePercent", this.sharingTimePercentLeft)
      if (this.sharingTimePercentLeft <= 0) {
        clearInterval(this.sharingTimeInterval);
      }
    },
    isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    ToggleSticker(sticker) {
      console.log("ToggleSticker", sticker)

      if(!this.options.stickers.multiple) {
        this.stickerList.forEach((x) => x.enabled = false);
      }

      sticker.enabled = !sticker.enabled;

      this.$forceUpdate();

      this.stickerList.forEach((x)=> {
        this.$socket.client.emit("gameMessage", {
          type: "StickerToggled",
          id: x.id,
          state: x.enabled,
          x: x.x,
          y: x.y,
        });
      })

     
    },
    onStickerDrag(sticker, x, y) {
      x /= this.dragContainerWidth - 75;
      y /= this.dragContainerHeight - 75;
      sticker.x = x;
      sticker.y = y;
      this.$forceUpdate();
      // emit to server
      // const pos = this.StickerPositionRemap(sticker.x,sticker.y)
      // console.log("*", pos.x, pos.y)
      this.$socket.client.emit("gameMessage", {
        type: "StickerMoved",
        id: sticker.id,
        x: sticker.x,
        y: sticker.y,
      });
    },
    StickerPositionRemap(x, y) {
      // Ingresa un x,y entre 0 y 1 que representa el cuadrado donde se ubican los stickers
      // Tenemos que mapearlo adentro de un trapecio definido por:
      // options.stickerLimitY es el alto maximo
      // options.stickerTopXScale es el ancho maximo arriba de todo
      // La base del trapecio es siempre 1
      let _y = utils.map(y, 0, 1, this.options.stickers.stickerLimitY, 1);
      // let _xmultiplier = utils.map(_y, 0, this.options.stickers.stickerLimitY, 1, this.options.stickers.stickerTopXScale)
      let topSidesOffset = (1 - this.options.stickers.stickerTopXScale) / 2;
      let xTopTarget = utils.map(
        x,
        0,
        1,
        topSidesOffset,
        Math.abs(topSidesOffset) + this.options.stickers.stickerTopXScale
      );

      let bottomSidesOffset =
        (1 - this.options.stickers.stickerBottomXScale) / 2;
      let xBottomTarget = utils.map(
        x,
        0,
        1,
        bottomSidesOffset,
        Math.abs(bottomSidesOffset) + this.options.stickers.stickerBottomXScale
      );
      let _x = utils.lerp(xTopTarget, xBottomTarget, y); // hago un lerp entre el x real y el calculado, segun la posicion en y
      // console.log("orig", x, y, "remap", _x, _y)
      return { x: _x, y: _y };
    },
    SavePhotos() {
      this.$store.commit('user/SetOpenGallery',true)
      this.$socket.client.emit("gameMessage", {
        type: "CaptureFinish",
      });
    },
    ReshootPhotos() {
      // Eliminar las fotos tomadas por ID con axios

      this.$socket.client.emit("gameMessage", {
        type: "ReshootPhoto",
        ids: this.capturedImages.map((image) => image.id),
      });
      this.screenTakingPicture = false;
      this.showCapturedImages = false;
      this.capturedImages = [];

      // Al tocar reshoot vuelve el contador inmediatamente,
      // elegir stickerr, moverlos o elegir cuando tomar la foto.
      // hay un socket captureStarted que cambia el subestado.
      // esta linea de abajo es un parche rapido porque
      // esta feature de ultimo momento y generò un bug. C'est la vie
      this.gameSubState = this.SUBSTATES.capturing;
    },
    TakePhoto() {
      this.showCapturedImages = false;
      this.capturedImages = [];
      this.$socket.client.emit("gameMessage", {
        type: "TakePhoto",
        userid: this.$store.state.user.profile.id,
      });
    },
    TakeVideo() {
      console.log("** TakeVideo");
      this.showCapturedVideo = false;
      this.capturedVideo = null;
      this.$socket.client.emit("gameMessage", {
        type: "TakeVideo",
        userid: this.$store.state.user.profile.id,
      });
      // Analytics
      this.$TrackUserEvent("Shoot Video", {
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      });
      console.log("Analytics Shoot Video");
    },
    ReshootVideo() {
      this.$socket.client.emit("gameMessage", {
        type: "ReshootVideo",
      });
      this.gameSubState = this.SUBSTATES.creating;
      // Analytics
      this.$TrackUserEvent("Reshoot Video", {
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      });
      console.log("Analytics ReShoot Video");
    },
    SaveVideos() {
      this.$socket.client.emit("gameMessage", {
        type: "SaveVideo",
      });
      // Analytics
      this.$TrackUserEvent("Save Video", {
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      });
      console.log("Analytics Save Video");
      this.$parent.GoToMenu();
    },
    async ShareVideo() {
      try {
        this.fetchingVideoShare = true;
        const response = await fetch(this.capturedVideo.url);
        if (!response.ok) {
          this.fetchingVideoShare = false;
          throw new Error("Network response was not ok");
        }

        const videoBlob = await response.blob();
        console.log("Blob type:", videoBlob.type); // Log the blob type

        const videoFile = new File(
          [videoBlob],
          "TOM-Experiencia-Photobooth.mp4",
          { type: "video/mp4" }
        );
        console.log("File:", videoFile); // Log the file

        let shareData = {
          files: [videoFile],
        };

        if (navigator.share && typeof navigator.share === "function") {
          if (navigator.canShare && navigator.canShare(shareData)) {
            this.fetchingVideoShare = false;
            await navigator.share(shareData);
          } else {
            // File sharing not supported
            console.error("File sharing is not supported in this browser.");
            this.fetchingVideoShare = false;
          }
        } else {
          // Fallback for browsers that do not support Web Share API
          // Convert blob to data URL and provide a link or use other methods for sharing
          const reader = new FileReader();
          reader.onload = function (event) {
            const dataUrl = event.target.result;
            // Use the data URL for sharing or provide a download link
            console.log("Data URL:", dataUrl);
            // Handle sharing or provide fallback for sharing on non-supported browsers
          };
          reader.readAsDataURL(videoBlob);
          this.fetchingVideoShare = false;
        }
      } catch (error) {
        // Handle errors here
        console.error("Error fetching and sharing video:", error);
        this.fetchingVideoShare = false;
      }
    },
    ShowConfetti() {
      var confetti = new ConfettiGenerator({
        target: "confetti-canvas",
        max: "80",
        size: "1",
        animate: true,
        props: ["triangle"],
        colors: [[212, 175, 55]],
        clock: "50",
        rotate: true,
        height: this.$refs.photobooth.clientHeight,
        start_from_edge: true,
        respawn: false,
      });

      confetti.render();
    },
    addRevealClass(event) {
      event.target.classList.add('reveal-animation');
    }
  },
  watch: {
    gameSubState(state) {
      if (state == this.SUBSTATES.captureFinished) return this.ShowConfetti();

      if (state == this.SUBSTATES.creating) {
        // Se escondio el tutorial
        // Calculo el alto del drag container
        // Drag container height

        if(!this.showDragContainer) return 

        this.$nextTick(() => {
          this.dragContainerWidth = this.$refs.dragContainer.offsetWidth;
          this.dragContainerHeight =
            (this.$refs.dragContainer.offsetWidth / 4) * 3;
        });

      }
    },
  },
  mounted() {
    console.log(this.options.stickers);
  },
  beforeUnmount() {},
  sockets: {
    stickersPosition(data) {
      console.log("stickersPosition", data);
      this.stickerList = [];
      if (this.showStickers) {
        this.options.stickers.list.forEach((item) => {
          const receivedStickerData = data.stickers.find(
            (x) => x.id == item.id
          );
          item.x = receivedStickerData.x * 0.8;
          item.y = receivedStickerData.y * 0.8;
          item.enabled = receivedStickerData.enabled;
          item.onDrag = (x, y) => {
            this.onStickerDrag(item, x, y);
          };
          this.stickerList.push(item);
        });
      }
    },
    convertingUploading() {
      this.gameSubState = this.SUBSTATES.convertingUploading;
    },
    countdownStarted() {
      console.log('countdownStarted')
      this.screenTakingPicture = true;
      this.showCapturedImages = false;
      this.gameSubState = this.SUBSTATES.startingCapture;
    },
    photoTaken() {
      this.screenTakingPicture = true;
      this.showCapturedImages = false;
    },

    capturedImages(data) {
      console.log("photosTaken", data);
      this.capturedImages = data.capturedImages;
      this.showCapturedImages = true;
      this.gameSubState = this.SUBSTATES.captureFinished;
    },
    capturedVideo(data) {
      console.log("capturedVideo", data);
      this.capturedVideo = data.video;
      this.showCapturedVideo = true;
      this.$nextTick(() => {
        console.log("Start loading", document.getElementById("capturedVideo"));
      });
      // this.$refs
      this.gameSubState = this.SUBSTATES.captureFinished;
      this.sharingTimePercentLeft = 100;
      this.sharingTimeStarted = Date.now();
      this.sharingTimeInterval = setInterval(this.SetSharingTimePercent, 50);
      // localStorage.setItem("openPhotoboothVideo", "1");
    },
    userDecideRedo() {
      this.gameSubState = this.SUBSTATES.captureFinished;
      this.sharingTimePercentLeft = 100;
      this.sharingTimeStarted = Date.now();
      this.sharingTimeInterval = setInterval(this.SetSharingTimePercent, 50);
      // localStorage.setItem("openPhotoboothVideo", "1");
    },
    captureStarted() {
      console.log('captureStarted')
      this.gameSubState = this.SUBSTATES.capturing;
    },
  },
};
</script>

<style src="./PhotoboothController.scss" lang="scss" scoped/>


<style>
.progress {
  border-radius: 4px;
}
</style>
