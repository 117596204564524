import { render, staticRenderFns } from "./PhotoboothController.vue?vue&type=template&id=2d6a7e7e&scoped=true&"
import script from "./PhotoboothController.vue?vue&type=script&lang=js&"
export * from "./PhotoboothController.vue?vue&type=script&lang=js&"
import style0 from "./PhotoboothController.scss?vue&type=style&index=0&id=2d6a7e7e&prod&lang=scss&scoped=true&"
import style1 from "./PhotoboothController.vue?vue&type=style&index=1&id=2d6a7e7e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d6a7e7e",
  null
  
)

export default component.exports